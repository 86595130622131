/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/tphatdev/cdn-tphatdev@9d682411eb54641ca75511e82da046f34076f124/auth.min.js
 * - /gh/tphatdev/cdn-tphatdev@9d682411eb54641ca75511e82da046f34076f124/carousel.min.js
 * - /gh/tphatdev/cdn-tphatdev@9d682411eb54641ca75511e82da046f34076f124/dropmenu.min.js
 * - /gh/tphatdev/cdn-tphatdev@9d682411eb54641ca75511e82da046f34076f124/script.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
